import { defaultFooter, extension } from "./Urls";
import React, { useState, useEffect } from "react";
import { Image, Card, Row, Col, Container, Spinner, Badge } from "react-bootstrap";
import ErrorStatusHandler from "./ErrorHelper/ErrorStatusHandler";
import Nessie from "../img/nessie.gif";
import "../css/cardtext.css";
import Typewriter from "typewriter-effect";

function NewsInfo() {
  const [isDataReady, setDataReady] = useState(false);
  const [newsdata, setNewsData] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    setDataReady(false);
    fetch("https://apexnews.apexlegends.me")
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          setError(ErrorStatusHandler(res.status));
          setDataReady(false);
        }
      })
      .then((result) => {
        setNewsData(result.items);
        setDataReady(true);
      })
      .catch((error) => {
        //Debug
        //setError(String(error));
      });
  }, []);

  const isMobile = /Android|webOS|Mobile|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  let perChunk = isMobile ? 2 : 3;
  let result = [];

  if (isDataReady && !error && newsdata.length > 0) {
    result = newsdata.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk);
      if (!resultArray[chunkIndex]) resultArray[chunkIndex] = [];
      resultArray[chunkIndex].push(item);
      return resultArray;
    }, []);
  }

  if (!isDataReady) {
    return (
      <div className="text-center">
        <Image src={Nessie} fluid />
        {error ? (
          <h1 style={{ color: "red" }}>{error}</h1>
        ) : (
          <h1>
            Loading <b>News</b> Information
            <Spinner animation="grow" size="sm" variant="danger" />
          </h1>
        )}
      </div>
    );
  } else {
    return (
      <div>
        <Container>
          <div className="text-center">
            {/* Extension */}
            {extension}
            <br />
            <h1>
              <b>
                <Typewriter
                  options={{
                    strings: ["Apex news articles", "Apex game updates"],
                    autoStart: true,
                    loop: true,
                    pauseFor: 2000,
                    cursor: "|",
                  }}
                />
              </b>
            </h1>
          </div>

          {/* News Articles */}
          {result.map((chunk, chunkIndex) => (
            <React.Fragment key={chunkIndex}>
              <Row>
                {chunk.map((article, idx) => (
                  <Col key={`${chunkIndex}-${idx}`}>
                    <Card style={styles.card}>
                      <Card.Link
                        href={`https://www.ea.com/games/apex-legends/apex-legends/news/${article?.slug}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Card.Img
                          variant="top"
                          src={article?.image?.ar16X9 + '&q=85&w=1280'}
                          style={styles.cardImage}
                          loading="lazy"
                        />
                      </Card.Link>
                      <Card.Footer className="cardfooter articlefooter">
                        <Badge pill
                          bg={
                            article?.type === 'News Article' ? "success" :
                              article?.type === 'Game Updates' ? "danger" :
                                "info"
                          }
                        >
                          {article?.type}
                        </Badge>
                        {' '}
                        {article?.summary}
                      </Card.Footer>
                    </Card>
                  </Col>
                ))}
              </Row>
              {chunkIndex < result.length - 1 && <hr className="my-4" />}
            </React.Fragment>
          ))}
        </Container>

        {/* Footer */}
        {defaultFooter}
      </div>
    );
  }
}

const styles = {
  card: {
    background: 0,
    border: null,
    borderRadius: 10,
  },
  cardImage: {
    borderRadius: 4,
    borderBottomLeftRadius: "unset",
    borderBottomRightRadius: "unset",
  },
};

export default NewsInfo;