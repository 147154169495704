import chromeStore from "../img/chrome_store.png";
import { Container } from "react-bootstrap";

const Hook_Path = "";
const showExtension = Math.random() < 0.5;

const defaultFooter = (
  <Container>
    <hr />
    <footer className="text-center">
      Copyright © 2020 - {new Date().getFullYear()}
      {" | "}
      <a href="https://github.com/younesk31" target="_blank" rel="noreferrer" style={{ color: "orange" }}>
        Slider Slayer
      </a>{" "}
      <br />
      All images, icons and trademarks belong to Apex Legends which is a registered trademark of Electronic Arts (EA).
    </footer>
    <br />
  </Container>
);

const extensionRand = showExtension ? (
  <span style={{ position: "fixed", transform: "translateX(300%)" }}>
    <a
      href="https://chrome.google.com/webstore/detail/apexinfo-map-updates/daepoakiopepeflefoedcpgoenpaiine"
      target="_blank"
      rel="noreferrer"
      style={{ color: "#ff7600", fontSize: 20 }}
    >
      <div>ApexInfo - Map Extension </div>
      <img src={chromeStore} alt="extension" />
    </a>
  </span>
) : (
  <span></span>
);

const extension = (
  <span style={{ position: "fixed", transform: "translateX(300%)" }}>
    <a
      href="https://chrome.google.com/webstore/detail/apexinfo-map-updates/daepoakiopepeflefoedcpgoenpaiine"
      target="_blank"
      rel="noreferrer"
      style={{ color: "#ff7600", fontSize: 20 }}
    >
      <div>ApexInfo - Map Extension </div>
      <img src={chromeStore} alt="extension" />
    </a>
  </span>
);

export {
  Hook_Path,
  defaultFooter,
  extension,
  extensionRand
};
